const DocMenuConfig = [
  {
    pages: [
      {
        heading: "Dashboard",
        route: "/dashboard",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        sectionTitle: "account",
        route: "/account",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Overview",
            route: "/account/overview",
          },
          {
            heading: "Locations",
            route: "/account/locations",
          },
          {
            heading: "Users",
            route: "/account/users",
          },
          {
            heading: "Contacts",
            route: "/contacts/search",
          },
          {
            heading: "Billing",
            route: "/account/billing",
          },
          {
            heading: "Plan",
            route: "/account/plan",
          },
          {
            heading: "Branding",
            route: "/account/branding",
          },
        ],
      },
      {
        heading: "My Profile",
        route: "/profile",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
];

export default DocMenuConfig;
